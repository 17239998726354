<template>
    <component :is="component" ></component>
</template>

<script>

    export default {
        data() {
            return {
                component: null,
                type: '',
            }
        },
        methods: {
            showComponent: function () {
                if (this.type === "income") {
                    this.component = () => import('../../components/widgets/incomeWidget/incomeWMitems.vue')
                } else if (this.type === "expense") {
                    this.component = () => import('../../components/widgets/expenseWidget/expenseWMitems.vue')
                } else if (this.type === "budget") {

                } else {
                    this.component = () => import('../../components/widgets/noDataWidget.vue')
                }
            },
        },
        beforeMount() {
            this.type = this.$route.query.name

            this.showComponent()
        }
    }

</script>